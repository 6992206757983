<template>
<!-- 订单详情的订单信息tab子组件 -->
  <el-form :model="form" ref="form" label-width="130px" class="disabled" :disabled="true">
      <div class="col col4">
        <el-form-item label="采购订单号">
          <el-input v-model="form.orderCode"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0">
        <el-form-item label="原供应商名称">
          <el-input v-model="form.providerName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 1">
          <el-form-item label="销售订单号">
            <el-input v-model="form.sellCode"></el-input>
          </el-form-item>
      </div>
      <div class="col col4" >
        <el-form-item label="公司名称">
          <el-input v-model="form.companyName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 1">
        <el-form-item label="销售员">
          <el-input v-model="form.salesmanName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0 ">
        <el-form-item label="原供应商销售员">
          <el-input v-model="form.salesmanName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0 || type == 1 ">
        <el-form-item label="采购员">
          <el-input v-model="form.buyerName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0 ">
        <el-form-item label="供应链服务商" >
          <el-input v-model="form.drawerName"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0 || type == 1 ">
        <el-form-item label="相关合同">
          <el-input v-model="form.contractCode"></el-input>
        </el-form-item>
      </div>
      <div class="col col4" v-if="type == 0 || type == 1 ">
          <el-form-item label="订单状态" >
            <el-select v-model="form.orderStatus" >
                <el-option label="待确认" :value="1"></el-option>
                <el-option label="已确认" :value="2"></el-option>
            </el-select>
          </el-form-item>
      </div>
  </el-form>

</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  name: 'OrderInformation',
  data () {
    return {
      form: { },
      type: this.$store.state.loginInfo.userType // userType  1  是供应商
    }
  },
  watch: {
    '$store.state.loginInfo.userType': function (val) {
      this.type = this.$store.state.loginInfo.userType
    }
  },
  mounted () {
    this.OrderInfor()
  },
  methods: {
    OrderInfor () { // 订单信息
      var orderCode = this.$route.query.orderCode
      requestForm('/api/order/order/getOrderDetailInfoByOrderCode?orderCode=' + orderCode, 'POST', '').then((response) => {
        if (response.code === '200') {
          response.data.ekkoInfo.forEach(item => {
            this.form = item
          })
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
</style>
