<template>
  <div class="mainform">
    <div class="mainHeader">订单详情
      <span @click="back" class="back">返回></span>
      <!-- 订单状态为已确认 2  userType  1  是供应商 可见编辑  -->
      <span @click="editInput" class="right" v-if="orderStatus === 2 && type === 1">编辑</span>
    </div>
    <div class="form">
      <table-tab :TabData="navData" @tabSwitch="handleClick" defaultData="info"></table-tab>
      <order-information ref="orderInformation" v-show="detailItem" class="content bascform"></order-information>
      <change-record v-show="!detailItem" class="content bascform"></change-record>
      <div v-show="detailItem">
        <div class="colorstyle"></div>
        <div class="detailtitle">明细项 </div>
        <global-table :tableField="tableField" ref="tabledetail" max-height="350" :tableData="tableData" @tableAction="tableAction"  @selection-change="SelectionChange"></global-table>
        <el-form v-show="detailItem" label-width="110px" class="content bascform" >
          <div class="tableBottom">
            <span>总计数量：{{totleNember}}</span>
            <span>总计金额：￥{{totlePrice}}</span>
          </div>
        </el-form>
        <div class="action" v-if="orderStatus == 1 ">
            <el-button class="submit" type="primary" size="medium" @click="updateOrderStatus">确认</el-button>
            <el-button  @click="print" type="primary" size="medium" >打印</el-button>
            <el-button  @click="download" type="primary" size="medium" >下载</el-button>
        </div>
        <div class="action" v-if="orderStatus == 2 ">
          <el-button  @click="print" type="primary" size="medium" >打印</el-button>
          <el-button  @click="download" type="primary" size="medium" >下载</el-button>
        </div>
      </div>
    </div>
    <dialog-box ref="editDialogbox"  :dialogShow="dialogShow" @handleClose="edidialogClose" :title="title"
      componentName="OrderdetailDialog" @edithandleClose="edithandleClose" :showData="showData" @addOrder="addOrder"
      :buttonData="buttonData"></dialog-box>
    <dialog-box :dialogShow="seedialogShow" dialogWidth="50%" @handleClose='seehandleClose' title="组件清单" :showData="showData" componentName="ComponentListdialog"
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'handleClose', type:'primary', size:'mini'}]">
    </dialog-box>
  </div>

</template>
<script>
import Component from '@/assets/js/components.js'
import { tableField, tableData, navData, suptableField } from './js/OrderDetail'
import { requestForm, request, exportForm } from '@/assets/js/http.js'
import OrderInformation from './OrderInformation'
import ChangeRecord from './ChangeRecord'
export default {
  name: 'OrderDetail',
  components: { ...Component.components, OrderInformation, ChangeRecord },
  data () {
    return {
      tableField: tableField,
      suptableField: suptableField,
      tableData: tableData,
      navData: navData,
      dialogShow: false, // 编辑按钮的弹窗
      detailItem: true, // 明细项是否显示
      seedialogShow: false, // 明细项查看的弹窗
      showData: {},
      buttonData: [],
      title: '',
      sellman: '',
      totleNember: '', // 总数量
      totlePrice: '', // 总金额
      orderStatusinfo: '',
      orderStatus: '',
      type: this.$store.state.loginInfo.userType,
      chooseData: [], // 明细项勾选的数据
      lineNum: '',
      action: false
    }
  },
  mounted () {
    this.OrderInfordetail()
  },
  methods: {
    OrderInfordetail () { // 明细项
      var orderCode = this.$route.query.orderCode
      requestForm('/api/order/order/getOrderDetailInfoByOrderCode?orderCode=' + orderCode, 'POST', '').then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.orderDetailInfoList
          if (this.$store.state.loginInfo.userType !== 0) { // 供应商1
            this.tableField = suptableField
          } else {
            this.tableField = tableField
          }
          this.totleNember = response.data.totleNember// 总数量
          this.totlePrice = response.data.totlePrice// 总金额
          this.orderStatusinfo = response.data.ekkoInfo
          this.orderStatusinfo.forEach(item => {
            this.orderStatus = item.orderStatus
          })
        }
      })
    },
    SelectionChange (selectData) {
      this.lineNum = ''
      selectData.forEach(item => {
        this.lineNum = this.lineNum + item.orderRow + ','
      })
      this.lineNum = this.lineNum.substring(0, this.lineNum.length - 1)
    },
    print () {
      var orderNum = this.$refs.orderInformation.form.orderCode
      console.log('0000', this.lineNum)
      if (this.lineNum) {
        exportForm('/api/order/order/printing?orderNum=' + orderNum + '&lineNum=' + this.lineNum, 'get').then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        })
      } else {
        exportForm('/api/order/order/printing?orderNum=' + orderNum, 'get').then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        })
      }
    },
    download () {
      var orderNum = this.$refs.orderInformation.form.orderCode
      console.log('0000', this.lineNum)
      if (this.lineNum) {
        exportForm('/api/order/order/printing?orderNum=' + orderNum + '&lineNum=' + this.lineNum, 'get').then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.download = '订单.pdf'
          eleLink.target = '_blank'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        })
      } else {
        exportForm('/api/order/order/printing?orderNum=' + orderNum, 'get').then((res) => {
          const url = window.URL.createObjectURL(res.data)
          const eleLink = document.createElement('a')
          eleLink.href = url
          eleLink.target = '_blank'
          eleLink.download = '订单.pdf'
          eleLink.click()
          window.URL.revokeObjectURL(url)
        })
      }
    },
    seehandleClose () {
      this.seedialogShow = false
    },
    edidialogClose () { // 编辑弹框关闭
      this.dialogShow = false
    },
    editInput (data) { // 点击编辑按钮 弹出dialog
      this.showData = undefined
      this.dialogShow = true
      this.title = '编辑'
      this.buttonData = [{ label: '取消', action: 'edithandleClose', size: 'mini' }, { label: '确认', action: 'addOrder', size: 'mini', type: 'primary' }]
    },
    // 保存编辑的订单和销售员信息
    addOrder () {
      var isPass = true
      var data = this.$refs.editDialogbox.$refs.content.$refs.form
      data.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      const obj = {
        ...this.$refs.editDialogbox.$refs.content.form,
        orderCode: this.$route.query.orderCode
      }
      request('/api/order/order/createSellOrderCode', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '添加成功',
            type: 'success'
          })
        }
        this.OrderInfordetail()
      })
      this.dialogShow = false
    },
    handleClick (data) { // tab点击切换 隐藏明细项
      if (data.props.name !== 'info') {
        this.detailItem = false
      } else {
        this.detailItem = true
      }
    },
    tableAction (index, data, act) { // 明细项查看按钮弹框
      this.seedialogShow = true
      this.showData = data
    },
    edithandleClose () {
      this.dialogShow = false
    },
    // 明细项下的确认和代确认按钮
    updateOrderStatus () {
      const obj = {
        orderCode: this.$route.query.orderCode,
        orderStatus: 2
      }
      requestForm('/api/order/order/updateOrderStatus', 'POST', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '确认成功',
            type: 'success'
          })
          this.$router.push({ path: '/ContractOrder/Myorder' })
        }
      })
    },
    // 返回
    back: () => {
      window.history.back()
    }
  }

}
</script>
<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  .tableList {
    padding-left: 0px;
    padding-right: 0;
  }
  .colorstyle{
    height: 1px;
    width: 1543px;
    background: #eee;
  }
  .action{
    margin-top: 40px;
  }
  .detailtitle{
    font-size: 14px;
    color: #666;
    font-weight: 600;
    margin: 50px 0 20px 0px;
  }
  .tableBottom{
    color: #666;
    font-size: 14px;
  }
  .back{
    margin-left: 25px;
  }
</style>
